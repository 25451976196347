import { useState } from 'react'
import ModalLayout from '@/components/layout/ModalLayout'
import SelectCoin from '@/components/modal/SelectCoin'
import CoinRow from '@/components/common/utils/CoinRow'
import styled, { css } from 'styled-components'

const CryptoCoinSelect = ({ coin, type, onChange, size = 30, heroOtc }) => {
    const [coinModal, setCoinModal] = useState(false)
    const openCoinModal = () => setCoinModal(true)
    const closeCoinModal = () => setCoinModal(false)

    return (
        <>
            <div onClick={openCoinModal} className='w-full'>
                <CoinRow coin={coin} size={size} />
            </div>
            <ModalLayout
                open={coinModal}
                onClose={closeCoinModal}
                width={'520px'}>
                <SelectCoin
                    onClose={closeCoinModal}
                    type={type}
                    onChange={onChange}
                />
            </ModalLayout>
        </>
    )
}

export default CryptoCoinSelect
