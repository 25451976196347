import styled from 'styled-components'
import { Flex } from '@/styles/styled'

const Input = ({ value, onChange, icon, label, ...rest }) => {
   return (
      <Wrapper {...rest}>
         <Flex fw fh>
            {!!icon && (
               <Flex fh width={'64px'}>
                  {icon}
               </Flex>
            )}
            <TInput value={value} onChange={onChange} placeholder={label} />
         </Flex>
      </Wrapper>
   )
}

const Wrapper = styled.div`
   width: ${(props) => props.width || '100%'};
   height: ${(props) => props.height || '42px'};
   border: 1px solid ${(props) => props.theme.primary}40;
   border-radius: 8px;

   &:hover {
      border: 1px solid ${(props) => props.theme.activeHover}80;
   }
`

const TInput = styled.input`
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   background-color: transparent;
   font-size: 1rem;
   color: ${(props) => props.theme.primary};
`

export default Input
