import { CFlex, DText, Flex, Grid } from '@/styles/styled'
import { ICON_URL, SOCKET_URL } from '@/core/constants/urls'
import Image from 'next/image'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Text from '@/components/common/utils/Text'
import { formatNumber } from '@/core/utils/common'

const CoinSearchRow = ({ coin, type, ...rest }) => {
   const { locale } = useRouter()
   const HAS_BADGE = coin.name === 'Gold' || coin.name === 'Dollar'

   return (
      <Wrapper>
         <Body {...rest}>
            <Grid gc={'50% 30% 20%'} style={{ height: '100%' }}>
               <Flex fw fh justify={'flex-start'}>
                  <Image
                     src={ICON_URL + `assets/icon/${coin.id}.png`}
                     width={40}
                     height={40}
                     style={{ margin: '0 6px' }}
                  />
                  <CFlex fh align={'flex-start'}>
                     <DText primary>
                        {locale === 'fa' ? coin.fa : coin.name}
                     </DText>
                     <DText primary>
                        {`${coin.id.toUpperCase()} ${coin.name}`}
                     </DText>
                  </CFlex>
               </Flex>
               <CFlex fw fh align={'flex-start'}>
                  <DText primary>
                     <Text tid={`${type}-price`} />
                  </DText>
                  <DText primary>
                     {formatNumber(coin[type], { type: 'irt' })}
                  </DText>
               </CFlex>
               <Flex fw>
                  {HAS_BADGE && (
                     <Image
                        src={'/assets/images/logo/badge.png'}
                        width={32}
                        height={32}
                        alt={'badge'}
                     />
                  )}
               </Flex>
            </Grid>
         </Body>
      </Wrapper>
   )
}

const Wrapper = styled.div`
   width: 100%;
   border-bottom: 1px solid ${(props) => props.theme.secondary}20;
   padding: 8px;
`

const Body = styled.div`
   width: 100%;
   height: 72px;
   padding: 8px;
   background-color: ${(props) =>
      props.active ? `${props.theme.secondary}20` : 'transparent'};
   border-radius: 8px;
   cursor: pointer;

   &:hover {
      background-color: ${(props) => props.theme.color}20;
   }
`

export default CoinSearchRow
