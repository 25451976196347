import {
   CFlex,
   CloseIcon,
   CMargin,
   DText,
   Flex,
   IconWrapper,
} from '@/styles/styled'
import Text from '@/components/common/utils/Text'
import { useEffect, useState } from 'react'
import Input from '@/components/common/input/Input'
import { BiSearch } from 'react-icons/bi'
import styled from 'styled-components'
import { useSocketContext } from '@/core/contexts/socket'
import CoinSearchRow from '@/components/common/utils/CoinSearchRow'
import { deepCopy } from '@/core/utils/common'
import { useRouter } from 'next/router'

const SelectCoin = ({ onClose, type, onChange, gold }) => {
   const { locale } = useRouter()
   const { pricesList: _prices } = useSocketContext()

   const [pricesList, setPricesList] = useState([])
   useEffect(() => {
      if (_prices) {
         if (gold) {
            const newPrices = _prices.filter(
               (i) => i.name === 'Gold' || i.name === 'Dollar'
            )
            setPricesList(newPrices)
         } else {
            setPricesList(_prices)
         }
      }
   }, [_prices])

   const [coins, setCoins] = useState([])

   useEffect(() => {
      if (pricesList?.length) {
         let prices = deepCopy(pricesList)
         if (search)
            prices = prices.filter((item) => {
               return (
                  item.id.includes(value) ||
                  item.fa.includes(value) ||
                  item.name.toLowerCase().includes(value?.toLowerCase())
               )
            })

         setCoins(prices)
      }
   }, [pricesList])

   const [search, setSearch] = useState()
   const onSearch = (e) => {
      const value = e?.target?.value
      setSearch(value)
      try {
         const newPrices = pricesList.filter((item) => {
            return (
               item?.id?.includes(value || '') ||
               item?.fa?.includes(value || '') ||
               item?.name?.toLowerCase()?.includes(value?.toLowerCase() || '')
            )
         })
         setCoins(newPrices)
      } catch (err) {}
   }

   const onCoinChange = (item) => {
      onChange(item)
      onClose()
   }

   return (
      <CFlex fw>
         <Flex fw justify={'space-between'}>
            <DText primary>
               <Text tid={'select-coin'} />
            </DText>
            <IconWrapper onClick={onClose}>
               <CloseIcon size={24} />
            </IconWrapper>
         </Flex>
         <CMargin margin={'6px'} />
         <Input
            icon={<SearchIcon size={24} />}
            value={search}
            onChange={onSearch}
            label={locale === 'fa' ? 'جستجو' : 'Search'}
            width={'95%'}
         />
         <CMargin margin={'6px'} />
         <CoinListWrapper>
            {coins.map((item) => (
               <CoinSearchRow
                  coin={item}
                  type={type}
                  onClick={() => onCoinChange(item)}
               />
            ))}
         </CoinListWrapper>
      </CFlex>
   )
}

const SearchIcon = styled(BiSearch)`
   color: ${(props) => props.theme.primary};
`

const CoinListWrapper = styled.div`
   height: 65vh;
   width: 100%;
   overflow-y: auto;
`

export default SelectCoin
