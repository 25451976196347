import { useOtc } from '@/components/main/otc/useOtc'
import Text from '@/components/common/utils/Text'
import CryptoCoinSelect from '@/components/main/otc/otc2/CryptoCoinSelect'
import ToomanRow from '@/components/main/otc/otc2/ToomanRow'
import { useRouter } from 'next/router'
import { useRunAfterUpdate } from '@/core/hooks/common/setting'
import {
    deformatNumber,
    formatNumber,
    onInputValueChangeUtil,
} from '@/core/utils/common'
import OtcInvoiceModal from '@/components/modal/OtcInvoiceModal'
import ModalLayout from '@/components/layout/ModalLayout'
import OtcErrorModal from '@/components/modal/OtcErrorModal'
import { BiUpArrowAlt } from 'react-icons/bi'
import { HiArrowLongUp } from 'react-icons/hi2'
import { IoIosArrowRoundUp } from 'react-icons/io'

const DigiOtc = () => {
    const { locale } = useRouter()
    const tabs = ['buy', 'sell']

    const {
        profile,
        balance,
        onBalanceClick,
        coin,
        price,
        onPriceChange,
        clientServed,
        volume,
        onVolumeChange,
        setCoin,
        onAction,
        modal,
        toomanCoin,
        onClose,
        limits,
        type,
        setType,
    } = useOtc()

    const afterUpdate = useRunAfterUpdate()
    const onChange = (e, inputType) => {
        const value = onInputValueChangeUtil(e, afterUpdate)
        if (inputType === 'first') {
            type === 'buy' ? onPriceChange(value) : onVolumeChange(value)
        } else {
            type === 'sell' ? onPriceChange(value) : onVolumeChange(value)
        }
    }

    return (
        <>
            <div
                className={
                    'card-body flex flex-col gap-5  shadow-2xl shadow-info border-2 border-info !rounded-xl'
                }>
                <div
                    className={
                        'flex order-0 items-center gap-2 justify-center'
                    }>
                    {tabs.map((item, index) => {
                        const isActive = item === type

                        return (
                            <div
                                className={`text-sm ${
                                    isActive
                                        ? 'text-black bg-primary-blue'
                                        : 'dark:bg-slate-800 bg-gray-300'
                                } flex justify-center items-center rounded-lg py-2 px-3 cursor-pointer shadow-md min-w-[72px]`}
                                onClick={() => setType(item)}>
                                <IoIosArrowRoundUp
                                    size={22}
                                    className={
                                        item === 'buy'
                                            ? 'rotate-180'
                                            : 'rotate-0'
                                    }
                                />
                                <Text tid={item} className={'text-sm'} />
                            </div>
                        )
                    })}
                </div>
                {!!profile ? (
                    <div
                        className={
                            'flex items-center justify-center gap-2 w-full text-sm cursor-pointer mx-[-4px]'
                        }
                        onClick={onBalanceClick}>
                        <Text tid={'balance'} />
                        <span>:</span>
                        <span className={'mt-[4px]'} dir={'ltr'}>
                            {balance}
                        </span>
                    </div>
                ) : null}
                <div
                    className={`flex flex-col gap-6 ${
                        !!profile ? 'mt-0' : 'mt-5'
                    }`}>
                    <div
                        className={
                            'rounded-md w-full h-[42px] otc-input-bg flex items-center px-3'
                        }>
                        {type === 'buy' ? (
                            <ToomanRow />
                        ) : (
                            <div
                                className={
                                    'flex justify-start items-start cursor-pointer w-full'
                                }>
                                <CryptoCoinSelect
                                    coin={coin}
                                    type={type}
                                    onChange={(c) => setCoin(c)}
                                />
                            </div>
                        )}
                    </div>
                    <div dir={'ltr'}>
                        <input
                            placeholder={
                                locale === 'fa'
                                    ? 'مقدار مبلغ پرداختی شما'
                                    : 'You pay'
                            }
                            value={
                                type === 'buy'
                                    ? price
                                        ? price
                                        : ''
                                    : volume
                                    ? volume
                                    : ''
                            }
                            onChange={(e) => onChange(e, 'first')}
                            className={
                                'h-full text-sm px-3 otc-input-bg border-none rounded-md w-full h-[42px]'
                            }
                        />
                    </div>

                    <div
                        className={
                            'rounded-md w-full h-[42px] otc-input-bg flex items-center px-3'
                        }>
                        {type === 'sell' ? (
                            <ToomanRow />
                        ) : (
                            <div
                                className={
                                    'flex justify-start items-start cursor-pointer w-full'
                                }>
                                <CryptoCoinSelect
                                    coin={coin}
                                    type={type}
                                    onChange={(c) => setCoin(c)}
                                />
                            </div>
                        )}
                    </div>
                    <div dir={'ltr'}>
                        <input
                            placeholder={
                                locale === 'fa'
                                    ? 'مقدار مبلغ دریافتی شما'
                                    : 'You receive'
                            }
                            value={
                                type === 'sell'
                                    ? price
                                        ? price
                                        : ''
                                    : volume
                                    ? volume
                                    : ''
                            }
                            onChange={(e) => onChange(e, 'second')}
                            className={
                                'h-full text-sm px-3 otc-input-bg border-none rounded-md w-full h-[42px]'
                            }
                        />
                    </div>
                </div>
                <div
                    className={
                        'w-full btn bg-blue-primary h-[42px] cursor-pointer mt-8'
                    }
                    onClick={() => onAction(type)}>
                    <Text tid={type} className={'text-white fontBold'} />
                </div>
            </div>
            {modal === 'otc' ? (
                <OtcInvoiceModal
                    state={type}
                    firstAsset={{
                        info: type === 'buy' ? toomanCoin : coin,
                        amount:
                            type === 'buy'
                                ? deformatNumber(price)
                                : deformatNumber(volume),
                        formattedNumber: type === 'buy' ? price : volume,
                    }}
                    secondAsset={{
                        info: type === 'sell' ? toomanCoin : coin,
                        amount:
                            type === 'sell'
                                ? deformatNumber(price)
                                : deformatNumber(volume),
                        formattedNumber: type === 'sell' ? price : volume,
                    }}
                    onClose={onClose}
                    coin={coin}
                />
            ) : (
                <ModalLayout
                    open={modal !== 'close'}
                    onClose={onClose}
                    width={'480px'}>
                    <OtcErrorModal
                        onClose={onClose}
                        limit={limits}
                        coin={coin}
                        type={modal}
                        tradeType={type}
                    />
                </ModalLayout>
            )}
        </>
    )
}

export default DigiOtc
