import Image from 'next/image'
import Text from '@/components/common/utils/Text'

export default function ToomanRow({}) {
    return (
        <div>
            <div className={'flex items-center justify-between gap-x-2'}>
                <Image
                    src={'/assets/images/logo/tooman.png'}
                    width={30}
                    height={30}
                    alt={'tooman'}
                />
                <div className={`md:text-sm `}>
                    <Text tid={'tooman'} />
                </div>
            </div>
        </div>
    )
}
